<template>
  <div>
    <el-card shadow="never">
      <div slot="header" class="clearfix">
        <span>消息中心（{{ messageNumber || 0 }}）</span>
      </div>
      <div class="clearfix">
        <el-button type="danger" plain @click="allread">全部已读</el-button>
      </div>
    </el-card>
    <el-card shadow="never" class="data-list">
      <div slot="header" class="clearfix">
        <span>消息列表</span>
      </div>
      <div class="table-box">
        <el-table :data="tableData" style="width: 100%">
          <el-table-column prop="messageType" label="名称" width="180" fixed>
            <template slot-scope="scope">
              <div class="name">
                <span class="red-dot" v-if="scope.row.readType == 801"></span>
                <span class="nametext">{{
                  scope.row.messageType | massageType
                }}</span>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="reminderContent" label="内容">
            <template slot-scope="scope">
              <div class="text">{{ scope.row.reminderContent }}</div>
            </template></el-table-column
          >
          <el-table-column prop="createTime" label="时间"> </el-table-column>
          <el-table-column label="操作" width="140px" fixed="right">
            <template slot-scope="scope">
              <el-button type="text" @click="look(scope.row)">查看</el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="pagination">
        <el-pagination
          :hide-on-single-page="total < pageSize"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="currentPage"
          :page-sizes="[6, 12, 24, 36, 100, 200]"
          :page-size="pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total"
        >
        </el-pagination>
      </div>
    </el-card>
    <el-dialog
      :title="textlist.name"
      :visible.sync="activityDialog"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :show-close="false"
      width="600px"
      center
    >
      <div>{{ textlist.reminderContent }}</div>
      <div class="time">
        {{ textlist.createTime }}
      </div>
      <div slot="footer" class="dialog-footer">
        <!-- <el-button @click="closeDialog">取 消</el-button> -->
        <el-button type="primary" @click="activityDialog = false"
          >确定</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { mapState, mapMutations } from "vuex";
export default {
  data() {
    return {
      currentPage: 1,
      pageSize: 6,
      total: null,
      tableData: [],
      textlist: {},
      activityDialog: false,
    };
  },
  filters: {
    massageType(type) {
      const statusMap = {
        701: "三天未发货",
        702: "发货提醒",
        703: "缺货提醒",
        704: "已发货提醒",
        705: "同意寄回",
        706: "同意退款",
        707: "退货成功",
        708: "退款成功",
      };
      return statusMap[type];
    },
  },
  computed: {
    //消息
    ...mapState("upload", ["messageNumber"]),
  },
  methods: {
    ...mapMutations("upload", ["getMessage"]),
    handleSizeChange(val) {
      this.pageSize = val;
      this.getData();
    },
    handleCurrentChange(val) {
      this.currentPage = val;
      this.getData();
    },
    look(row) {
      this.textlist = row;
      this.activityDialog = true;
      this.textlist.name = this.massageType(row.messageType);
      this.closeDialog(row.id);
    },
    massageType(type) {
      const statusMap = {
        701: "三天未发货",
        702: "发货提醒",
        703: "缺货提醒",
        704: "已发货提醒",
        705: "同意寄回",
        706: "同意退款",
        707: "退货成功",
        708: "退款成功",
      };
      return statusMap[type];
    },
    async closeDialog(id) {
      let { data } = await this.$base.postAjax(
        "/mallUser/AdminMessage/readMessage",
        // {
        //   code: "string",
        //   data: {
        //     accountNumber: JSON.parse(sessionStorage.getItem("login"))
        //       .accountNumber,
        //     id: id,
        //   },
        //   time: "string",
        // }

        {
          code: "",
          data: {
            accountNumber: JSON.parse(sessionStorage.getItem("login"))
              .accountNumber,
            id: [id],
            opType: 0,
          },
          time: "",
        }
      );
      if (data.code == 200) {
        this.currentPage = 1;
        this.massageNum();
        this.getData();
      } else {
        this.$message.warning(data.msg);
      }
    },
    async allread() {
      let { data } = await this.$base.postAjax(
        "/mallUser/AdminMessage/readMessage",
        {
          code: "",
          data: {
            accountNumber: JSON.parse(sessionStorage.getItem("login"))
              .accountNumber,
            id: [],
            opType: 1,
          },
          time: "",
        }
      );
      if (data.code == 200) {
        this.currentPage = 1;
        this.massageNum();
        this.getData();
      } else {
        this.$message.warning(data.msg);
      }
    },
    //获取数据
    async getData() {
      let { data } = await this.$base.postAjax(
        "/mallUser/AdminMessage/getAdminMessageS",
        {
          code: "string",
          data: {
            accountNumber: JSON.parse(sessionStorage.getItem("login"))
              .accountNumber,
            readType: 0,
          },
          page: this.currentPage,
          pageSize: this.pageSize,
          time: "string",
        }
      );
      if (data.code == 200) {
        this.tableData = data.data.data;
        this.total = data.data.total;
      } else {
        this.$message.warning(data.msg);
      }
    },
    async massageNum() {
      let { data } = await this.$base.postAjax(
        "/mallUser/AdminMessage/getAdminMessageNumber",
        {
          code: "string",
          data: "string",
          time: "string",
        }
      );
      if (data.code == 200) {
        this.getMessage(data.data);
      } else {
        this.$message.warning(data.msg);
      }
    },
  },
  created() {},
  mounted() {
    this.getData();
  },
};
</script>
<style lang="scss" scoped>
.text {
  width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.time {
  margin-top: 10px;
  text-align: right;
}
.red-dot {
  position: absolute;

  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: red;
  display: inline-block;
}
.nametext {
  margin-left: 10px;
}
</style>
